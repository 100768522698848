var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "굴착작업 sketch", height: "405px" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c("c-pick-file", {
                      attrs: { outlined: "", dense: true },
                      on: { getImageData: _vm.getImageData },
                      scopedSlots: _vm._u([
                        {
                          key: "prepend",
                          fn: function () {
                            return [
                              _c("q-icon", { attrs: { name: "attach_file" } }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.file,
                        callback: function ($$v) {
                          _vm.file = $$v
                        },
                        expression: "file",
                      },
                    }),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("q-img", {
                        staticStyle: {
                          "min-width": "150px",
                          "max-width": "550px",
                        },
                        attrs: {
                          src: _vm.supWork.mapSrc,
                          fit: "scale-down",
                          ratio: 1,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "loading",
                            fn: function () {
                              return [
                                _c("q-spinner-gears", {
                                  attrs: { color: "white" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8" },
          [
            _c("c-table", {
              attrs: {
                isTitle: "",
                title: "안전조치 요구사항",
                columns: _vm.grid.columns,
                gridHeightAuto: true,
                data: _vm.supWork.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }